import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
{
  path: '/users',
  name: 'Users',
  component: () => import(/* webpackChunkName: "channels" */ '../views/Users.vue')
},
{
  path: '/users/:id',
  name: 'EditUser',
  component: () => import(/* webpackChunkName: "channels" */ '../views/EditUser.vue')
},
{
  path: '/newsletter',
  name: 'Newsletter',
  component: () => import(/* webpackChunkName: "channels" */ '../views/Newsletter.vue')
},
{
  path: '/notifications',
  name: 'Notification',
  component: () => import(/* webpackChunkName: "channels" */ '../views/NewPush.vue')
},
{
  path: '/reports',
  name: 'Reports',
  component: () => import(/* webpackChunkName: "channels" */ '../views/Reports.vue')
},
{
  path: '/comments',
  name: 'Comments',
  component: () => import(/* webpackChunkName: "channels" */ '../views/Comments.vue')
},
{
  path: '/posts',
  name: 'Posts',
  component: () => import(/* webpackChunkName: "channels" */ '../views/Posts.vue')
},
{
  path: '/new-post',
  name: 'NewPost',
  component: () => import(/* webpackChunkName: "channels" */ '../views/NewPost.vue')
},
{
  path: '/new-video',
  name: 'NewVideo',
  component: () => import(/* webpackChunkName: "channels" */ '../views/NewVideo.vue')
},
{
  path: '/videos',
  name: 'Videos',
  component: () => import(/* webpackChunkName: "channels" */ '../views/Videos.vue')
},
{
  path: '/signin',
  name: 'signin',
  component: () => import(/* webpackChunkName: "channels" */ '../views/Signin.vue')
},
{
  path: '/channels',
  name: 'Channels',
  component: () => import(/* webpackChunkName: "channels" */ '../views/Channels.vue')
},
{
  path: '/channels/:id',
  name: 'Channel',
  component: () => import(/* webpackChunkName: "channels" */ '../views/Channel.vue')
},
{
  path: '/anonymous-feed',
  name: 'AnonymousFeed',
  component: () => import(/* webpackChunkName: "channels" */ '../views/AnonymousFeed.vue')
},
{
  path: '/dashboard',
  name: 'Dashboard',
  component: () => import(/* webpackChunkName: "channels" */ '../views/Dashboard.vue')
},
{
  path: '/feed',
  name: 'Feed',
  component: () => import(/* webpackChunkName: "channels" */ '../views/Feed.vue')
}
]

const router = new VueRouter({
  routes
})

export default router
