<template>
  <div>
    <nav class="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow">
      <a class="navbar-brand col-sm-3 col-md-2 mr-0" href="#">Kolel</a>
      <input class="form-control form-control-dark w-100" type="text" placeholder="Search" aria-label="Search">
      <ul class="navbar-nav px-3">
        <li class="nav-item text-nowrap">
          <a class="nav-link" href="#" @click="signout()">Sign out</a>
        </li>
      </ul>
    </nav>

    <div class="container-fluid">
      <div class="row">
        <nav class="col-md-2 d-none d-md-block bg-light sidebar">
          <div class="sidebar-sticky">
            <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
              <span>Content Control</span>
              <a class="d-flex align-items-center text-muted" href="#" aria-label="Add a new report">
                <span data-feather="plus-circle"></span>
              </a>
            </h6>
            <ul class="nav flex-column mb-2">
              <li class="nav-item">
                <router-link class="nav-link" :to="'/feed'">
                  <span data-feather="file-text"></span>
                  Feed
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="'/channels'">
                  <span data-feather="file-text"></span>
                  Channels
                </router-link>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">
                  <span data-feather="file-text"></span>
                  Add a channel
                </a>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="'/posts'">
                  <span data-feather="file-text"></span>
                  Posts
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="'/new-post'">
                  <span data-feather="file-text"></span>
                  Add a post
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="'/videos'">
                  <span data-feather="file-text"></span>
                  Videos
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="'/new-video'">
                  <span data-feather="file-text"></span>
                  Add a video
                </router-link>
              </li>
            </ul>
            <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
              <span>Application</span>
              <a class="d-flex align-items-center text-muted" href="#" aria-label="Add a new report">
                <span data-feather="plus-circle"></span>
              </a>
            </h6>
            <ul class="nav flex-column mb-2">
              <li class="nav-item">
                <router-link class="nav-link" :to="'/dashboard'">
                  <span data-feather="file-text"></span>
                  Dashboard
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="'/anonymous-feed'">
                  <span data-feather="file-text"></span>
                  Anonymous Feed
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="'/users'">
                  <span data-feather="file-text"></span>
                  Users
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="'/comments'">
                  <span data-feather="file-text"></span>
                  Comments
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="'/reports'">
                  <span data-feather="file-text"></span>
                  Reports
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="'/newsletter'">
                  <span data-feather="file-text"></span>
                  Newsletter
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="'/notifications'">
                  <span data-feather="file-text"></span>
                  Notifications
                </router-link>
              </li>
            </ul>
          </div>
        </nav>

        <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-4">
          <router-view />
        </main>
      </div>
    </div>
    <balloon v-if="selectedPost"
    :title="selectedPost.title"
    @close="selectedPost = null"
    position='bottom-right'>
    <video style="width: 100%; height: 100%" v-bind:src="selectedPost.video_url" controls autoplay preload="metadata"></video> 
  </balloon>
</div>

</template>
<script>  
  import { Balloon } from 'vue-balloon'
  import eventHub from './eventHub'

  export default {
    data() {
      return {
        selectedPost: null
      }
    },
    mounted(){
      eventHub.$on('play-video', (video) => {
        this.selectedPost = video;
      })
    },
    beforeDestroy() {      
      eventHub.$off('play-video')
    },
    methods: {
      signout() {
        localStorage.removeItem('token');
        this.$router.push('/signin').catch(() => {})
      }
    },
    components: { Balloon }
  }
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.fa-close
{
  --color: #444;
  --l: 5px; /* line-width */
  width: 15px;
  height: 15px;
  background: linear-gradient(to top right, transparent calc(50% - var(--l) / 2), var(--color) calc(50% - var(--l) / 2) calc(50% + var(--l) / 2), transparent calc(50% + var(--l) / 2)),
  linear-gradient(to bottom right, transparent calc(50% - var(--l) / 2), var(--color) calc(50% - var(--l) / 2) calc(50% + var(--l) / 2), transparent calc(50% + var(--l) / 2));
  
  --clip-path: polygon(var(--l) 0%, calc(100% - var(--l)) 0%, 100% var(--l), 100% calc(100% - var(--l)), calc(100% - var(--l)) 100%, var(--l) 100%, 0% calc(100% - var(--l)), 0% var(--l));
  -webkit-clip-path: var(--clip-path);
  clip-path: var(--clip-path);
}
</style>
