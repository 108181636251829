//eslint-disable-next-line no-console
/* eslint-disable no-debugger */

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Axios from 'axios'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import jwtDecode from 'jwt-decode'
import Paginate from 'vuejs-paginate'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faLock, faLockOpen, faUnlock, faCalendarTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/style.css';

import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'

Vue.config.productionTip = false

Vue.use(Datetime)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

library.add(faLock, faLockOpen, faUnlock, faCalendarTimes)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)

Vue.component('paginate', Paginate)
Vue.component('datetime', Datetime);

router.beforeEach((to, from, next) => {  
  let token = localStorage.getItem("token");    
  if (to.name != 'signin' && !token) {
    next('/signin');
    return;
  } else if (to.name != 'signin' && token) {
    try {
      let payload = jwtDecode(token)
      if (Date.now() / 1000 > payload.exp) {
        next('/signin');
        return;
      }
    } catch (ex)
    {
      next('/signin');
      return;
    }
  }

  next();
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

Vue.prototype.$host = process.env.NODE_ENV ==='production' ? 'https://api.kolel.org' : 'http://localhost:3000';

Vue.prototype.$http = Axios.create({
  baseURL: Vue.prototype.$host
});

Vue.prototype.$http.defaults.headers.common['TOKEN'] = localStorage.getItem("token");

Vue.prototype.$http.interceptors.response.use(function(response) {  
  return response;
}, function(error) {  
  if (error.status >= 400 && error.status < 500) router.push({
    name: 'signin'
  });
    return Promise.reject(error);
  });

String.prototype.toHHMMSS = function (friendly = false) {
    var sec_num = parseInt(this, 10); // don't forget the second param
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    
    if (friendly)
      return `${hours}h ${minutes}m ${seconds}s`;

    return hours+':'+minutes+':'+seconds;
  }
